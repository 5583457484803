export default {
  //Composition Builder
  RESET: 'RESET',
  SELECT_PRIMARY_FLAVOR: 'SELECT_PRIMARY_FLAVOR',
  SELECT_KEYSTONE: 'SELECT_KEYSTONE',
  SELECT_PRIMARY_T1: 'SELECT_PRIMARY_T1',
  SELECT_PRIMARY_T2: 'SELECT_PRIMARY_T2',
  SELECT_PRIMARY_T3: 'SELECT_PRIMARY_T3',
  SELECT_SECONDARY_FLAVOR: 'SELECT_SECONDARY_FLAVOR',
  SELECT_SECONDARY_RUNES: 'SELECT_SECONDARY_RUNES',
  TOGGLE_MENU: 'TOGGLE_MENU',
  TOGGLE_INFO_DISPLAY: 'TOGGLE_INFO_DISPLAY',
  LOAD_FROM_PERMALINK: 'LOAD_FROM_PERMALINK',
  LOAD_FROM_PERMALINK_ERROR: 'LOAD_FROM_PERMALINK_ERROR',
  MAKE_PERMALINK: 'MAKE_PERMALINK',
  TRIGGER_SLOT: 'TRIGGER_SLOT',
  RESET_SLOT_MACHINE: 'RESET_SLOT_MACHINE',
  LOAD_IMAGE: 'LOAD_IMAGE',
  RIOT_API_REQUEST_PENDING: 'RIOT_API_REQUEST_PENDING',
  RIOT_API_REQUEST_SUCCESS: 'RIOT_API_REQUEST_SUCCESS',
  RIOT_API_REQUEST_FAILED: 'RIOT_API_REQUEST_FAILED',

  //Editor
  SET_PATH_TITLE: 'SET_PATH_TITLE',
  SET_PATH_SUBTITLE: 'SET_PATH_SUBTITLE',
  SET_KEYSTONE_NAME: 'SET_KEYSTONE_NAME',
  SET_KEYSTONE_TOOLTIP: 'SET_KEYSTONE_TOOLTIP',
  SET_KEYSTONE_DETAILS: 'SET_KEYSTONE_DETAILS',
  SET_TIER_TITLE: 'SET_TIER_TITLE',
  SET_RUNE_NAME: 'SET_RUNE_NAME',
  SET_RUNE_TOOLTIP: 'SET_RUNE_TOOLTIP',
  SET_RUNE_DETAILS: 'SET_RUNE_DETAILS',
  ADD_RUNE: 'ADD_RUNE',
  ADD_KEYSTONE: 'ADD_KEYSTONE',
  SET_COLOR: 'SET_COLOR',
  TOGGLE_COLOR_PICKER: 'TOGGLE_COLOR_PICKER',
  SET_CHOSEN_PATH: 'SET_CHOSEN_PATH',
  SELECT_IMAGE: 'SELECT_IMAGE',
  SET_RUNE_IMAGE: 'SET_RUNE_IMAGE',
  SET_KEYSTONE_IMAGE: 'SET_KEYSTONE_IMAGE',
  RESET_EDITOR: 'RESET_EDITOR',
  //Firestore
  SAVE_NEW_PATH: 'SAVE_NEW_PATH',
  NEW_PATH_ERROR: 'NEW_PATH_ERROR',
  LOAD_PATH: 'LOAD_PATH',
  LOAD_PATH_ERROR: 'LOAD_PATH_ERROR',
  LOAD_ALL_PATHS: 'LOAD_ALL_PATHS',
  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  // Resume
  LOAD_RESUME: 'LOAD_RESUME',
  SELECT_RESUME_KEYSTONE: 'SELECT_RESUME_KEYSTONE',
  SELECT_RESUME_TIER1: 'SELECT_RESUME_TIER1',
  SELECT_RESUME_TIER2: 'SELECT_RESUME_TIER2',
  SELECT_RESUME_TIER3: 'SELECT_RESUME_TIER3',
  TOGGLE_RESUME_MENU: 'TOGGLE_RESUME_MENU',
  TOGGLE_RESUME_INFO: 'TOGGLE_RESUME_INFO',
  // Resume Editor
  LOAD_RESUME_EDITOR: 'LOAD_RESUME_EDITOR',
  SAVE_RESUME_EDITOR: 'SAVE_RESUME_EDITOR',
  SET_PDF: 'SET_PDF',
  SAVE_PDF: 'SAVE_PDF',
}
